import { VACATION_HOURS, VACATION_HOURS_INDIVIDUAL } from 'const';

export function getDaysQtyInYear(year) {
  return (year % 4 === 0 && year % 100 > 0) || year % 400 === 0 ? 366 : 365;
}

export function getDayNumberInYear(date) {
  return (
    (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) /
    24 /
    60 /
    60 /
    1000
  );
}

export function getVacationHours(userEmail) {
  if (VACATION_HOURS_INDIVIDUAL[userEmail]) {
    return VACATION_HOURS_INDIVIDUAL[userEmail];
  }

  return VACATION_HOURS;
}
