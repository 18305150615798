import axios from 'axios';

const fetchFills = async (user, supervisorMode) => {
  const params = {};
  if (supervisorMode) {
    params.project = 'Trading Technologies';
  } else {
    params.user = user;
  }
  const response = await axios.get('https://pm.saldvin.com/api/getTime.php', { params });

  if (response.status === 200) {
    return response.data || [];
  }
  return [];
};

const postFill = async ({ date, duration, project_name, project_type }, user_email) => {
  const data = new FormData();
  data.append('date', date);
  data.append('duration', duration);
  data.append('project_name', project_name);
  data.append('project_type', project_type);
  data.append('user_email', user_email);

  try {
    const response = await axios.post('https://pm.saldvin.com/api/addTime.php', data);
    return response.status === 200;
  } catch {
    return false;
  }
};

const deleteFill = async (id, user_email) => {
  const data = new FormData();
  data.append('id', id);
  data.append('user_email', user_email);

  try {
    const response = await axios.post('https://pm.saldvin.com/api/deleteTime.php', data);
    return response.status === 200;
  } catch {
    return false;
  }
};

const reportService = { deleteFill, fetchFills, postFill };
export default reportService;
