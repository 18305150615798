import { makeAutoObservable, runInAction } from 'mobx';
import { forEach } from 'lodash';
import { userStore } from 'stores';
import { getDayNumberInYear, getDaysQtyInYear, getVacationHours } from 'utils/timeUtils';
import api from '../services';

export class ReportStore {
  constructor(OAuthService) {
    this.service = OAuthService;
    this.loading = false;
    this.timeFills = {};
    this.timeFillsByUser = {};
    this.supervisorMode = false;
    this.vacationDaysLeft = false;
    this.year = new Date().getFullYear();
    makeAutoObservable(this);
  }

  loadTimeFills = async () => {
    runInAction(() => {
      this.loading = true;
    });

    try {
      await userStore.loadUsers(this.supervisorMode);
      const result = await this.service.fetchFills(userStore.user.email, this.supervisorMode);
      const timeFills = {};
      const timeFillsByUser = {};
      result.forEach((item) => {
        const timeFill = {
          duration: item.duration,
          projectName: item.project_name,
          projectType: item.project_type,
          id: item.id,
          user: userStore.getUserNameByEmail(item.user_email) || item.user_email,
          userEmail: item.user_email,
        };
        if (!timeFills[item.date]) {
          timeFills[item.date] = [timeFill];
        } else {
          timeFills[item.date].push(timeFill);
        }

        const email = item.user_email;
        const monthYear = item.date.slice(0, 7); // "2024-05-21" -> 2024-05"
        const durationNum = parseFloat(item.duration);

        if (!timeFillsByUser[email]) {
          timeFillsByUser[email] = {
            [monthYear]: {
              [item.project_type]: durationNum,
            },
          };
        } else if (!timeFillsByUser[email][monthYear]) {
          timeFillsByUser[email][monthYear] = {
            [item.project_type]: durationNum,
          };
        } else if (!timeFillsByUser[email][monthYear][item.project_type]) {
          timeFillsByUser[email][monthYear][item.project_type] = durationNum;
        } else {
          timeFillsByUser[email][monthYear][item.project_type] += durationNum;
        }
      });
      runInAction(() => {
        this.timeFills = timeFills;
        this.timeFillsByUser = timeFillsByUser;
      });

      this.calculateVacations();
    } catch (err) {
      console.log(err);
    }
    runInAction(() => {
      this.loading = false;
    });
  };

  postTimeFills = async (fill, email) => {
    this.setLoading(true);
    try {
      const posted = await this.service.postFill(fill, email);
      // const posted = await this.service.postFill(fill, 'yevhen.pushkarov@saldvin.com');
      if (posted) {
        await this.loadTimeFills();
      }
    } catch (err) {
      console.log(err);
    }
    this.setLoading(false);
    return this.timeFills;
  };

  deleteTimeFill = async (id) => {
    this.setLoading(true);
    try {
      const posted = await this.service.deleteFill(id, userStore.user.email);
      if (posted) {
        await this.loadTimeFills();
      }
    } catch (err) {
      console.log(err);
    }
    this.setLoading(false);
    return this.timeFills;
  };

  setLoading = (value) => {
    runInAction(() => {
      this.loading = value;
    });
  };

  setSupervisorMode = (isSupervisorMode) => {
    this.supervisorMode = isSupervisorMode;
    this.loadTimeFills();
  };

  calculateVacations = () => {
    const user = userStore.customUser || userStore.user;
    if (user?.startDate) {
      const startDate = new Date(user.startDate);
      const currentYearStr = String(this.year);
      let vacationsHoursUsed = 0;
      forEach(this.timeFills, (day, strDate) => {
        if (strDate.substring(0, 4) !== currentYearStr) {
          return;
        }
        const hoursForTheDay = day.reduce((acc, current) => {
          if (current.projectType === 'VACATION' && user.email === current.userEmail) {
            return acc + parseFloat(current.duration);
          }
          return acc;
        }, 0);
        vacationsHoursUsed += hoursForTheDay;
      });

      const defaultVacationHours = getVacationHours(user.email);

      let remainingVacationHours = defaultVacationHours;
      let remainingVacationHoursRounded = defaultVacationHours;
      if (startDate.getFullYear() === this.year) {
        const startDayInYear = getDayNumberInYear(startDate);
        const daysInYear = getDaysQtyInYear(this.year);
        remainingVacationHours = (defaultVacationHours / daysInYear) * (daysInYear - startDayInYear);
        const vacationDays =
          remainingVacationHours % 1 > 0.6
            ? Math.ceil(remainingVacationHours / 8)
            : Math.floor(remainingVacationHours / 8);
        remainingVacationHoursRounded = vacationDays * 8;
      }
      const vacationDays = (
        (remainingVacationHoursRounded + user.transferedVacationHours - vacationsHoursUsed) /
        8
      ).toFixed('1');

      runInAction(() => {
        this.vacationDaysLeft = vacationDays.slice(-1) === '0' ? vacationDays.slice(0, -2) : vacationDays;
      });
    }
  };
}

export const reportStore = new ReportStore(api.reportService);
