import React from 'react';
import { User } from 'components';
import s from './Header.module.scss';

function Header() {
  return (
    <div className={s.root}>
      <div className={s.left}>
        <div className={s.logo}></div>
        <div className={s.title}>Project Management</div>
      </div>
      <User />
    </div>
  );
}

export default Header;
