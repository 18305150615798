import axios from 'axios';

const CLIENT_ID = '91441803819-quln77nh5rni5e4cm5isk4a4o6un15rk.apps.googleusercontent.com';
const REDIRECT_URI = window.location.origin;
// const REDIRECT_URI = 'http://localhost:3000';

// function loadUserOauthData() {
//   return axios
//     .get(
//       `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A//www.googleapis.com/auth/drive.metadata.readonly&include_granted_scopes=true&response_type=token&state=try_sample_request&redirect_uri${REDIRECT_URI}&client_id=${CLIENT_ID}`
//     )
//     .then((payload) => payload.data);
// }

function oauth2SignIn() {
  // Google's UserService 2.0 endpoint for requesting an access token
  const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';

  // Create element to open UserService 2.0 endpoint in new window.
  const form = document.createElement('form');
  form.setAttribute('method', 'GET'); // Send as a GET request.
  form.setAttribute('action', oauth2Endpoint);

  // Parameters to pass to UserService 2.0 endpoint.
  const params = {
    client_id: CLIENT_ID,
    redirect_uri: REDIRECT_URI,
    scope: 'https://www.googleapis.com/auth/userinfo.email',
    state: 'try_sample_request',
    include_granted_scopes: 'true',
    response_type: 'token',
  };

  // Add form parameters as hidden input values.
  for (const p in params) {
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', p);
    input.setAttribute('value', params[p]);
    form.appendChild(input);
  }

  // Add form to page and submit it to open the UserService 2.0 endpoint.
  document.body.appendChild(form);
  form.submit();
}

const ALLOWED_DOMAINS = ['saldvin.com', 'tradingtechnologies.com', 'trade.tt'];

function requestOauthUserData() {
  const params = JSON.parse(localStorage.getItem('oauth2-test-params'));
  if (params && params['access_token']) {
    return axios
      .get(`https://www.googleapis.com/oauth2/v3/userinfo?alt=json&access_token=${params['access_token']}`)
      .then((payload) => {
        if (payload.status === 200) {
          const domain = payload.data.email.split('@')[1];

          if (!ALLOWED_DOMAINS.includes(domain)) {
            payload.data.restricted = true;
          }
          return payload.data;
        } else if (payload.status === 401) {
          oauth2SignIn();
        }
      })
      .catch((err) => {
        oauth2SignIn();
      });
    // xhr.onreadystatechange = function (e) {
    //   if (xhr.readyState === 4 && xhr.status === 200) {
    //     console.log(xhr.response);
    //   } else if (xhr.readyState === 4 && xhr.status === 401) {
    //     // Token invalid, so prompt for user permission.
    //     oauth2SignIn();
    //   }
    // };
  } else {
    oauth2SignIn();
  }
}

const fetchUsers = async (email, supervisorMode) => {
  const params = {};
  if (!supervisorMode) {
    params.email = email;
  }
  const response = await axios.get('https://pm.saldvin.com/api/getUsers.php', { params });

  if (response.status === 200) {
    return response.data || [];
  }
  return [];
};

const oAuthServices = { fetchUsers, oauth2SignIn, requestOauthUserData };
export default oAuthServices;
