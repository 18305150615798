import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { find, forEach, orderBy } from 'lodash';
import { Alert, Badge, Calendar, Checkbox, InputNumber, Modal, Tag, Select, Switch } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { reportStore as store, userStore } from 'stores';
import { PageLayout } from 'components';
import s from './Report.module.scss';

const PROJECT_TYPES = {
  WORK: 'WORK',
  ILLNESS: 'ILLNESS',
  VACATION: 'VACATION',
  TIME_OFF: 'TIME_OFF',
  HOLIDAY: 'HOLIDAY',
};

const COLORS = {
  [PROJECT_TYPES.WORK]: 'green',
  [PROJECT_TYPES.ILLNESS]: 'volcano',
  [PROJECT_TYPES.VACATION]: 'geekblue',
  [PROJECT_TYPES.HOLIDAY]: 'purple',
};

const PROJECTS = [
  { value: 'TT', label: 'Trading Technologies', type: PROJECT_TYPES.WORK },
  { value: 'TimeOff', label: 'Time Off', type: PROJECT_TYPES.TIME_OFF },
];

const TIME_OFFS = [
  { value: 'Illness', label: 'Illness', type: PROJECT_TYPES.ILLNESS },
  { value: 'Vacation', label: 'Vacation', type: PROJECT_TYPES.VACATION },
];

function Report() {
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [currentProject, setCurrentProject] = useState(PROJECTS[0]);
  const [currentTimeOff, setCurrentTimeOff] = useState(TIME_OFFS[0]);
  const [showVacationDaysLeft, setShowVacationDaysLeft] = useState(false);
  const [currentTime, setCurrentTime] = useState(8);
  const [displayDate, setDisplayDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedMonthYear, setSelectedMonthYear] = useState(new Date().toISOString().slice(0, 7));
  const [displayTimeOff, setDisplayTimeOff] = useState(false);
  const [applyToAll, setApplyToAll] = useState(false);
  const [customUserEmail, setCustomUserEmail] = useState(null);
  const [showUserSelect, setShowUserSelect] = useState(false);

  useEffect(() => {
    if (userStore.user?.email) {
      store.loadTimeFills();
      setCustomUserEmail(userStore.user.email);
    }
  }, [userStore.user?.email]);

  useEffect(() => {
    userStore.setCustomUserEmail(customUserEmail, store.supervisorMode);
    store.calculateVacations();
  }, [customUserEmail, store.supervisorMode]);

  const handleModalClose = () => {
    store.setLoading(true);
    setDisplayDate(null);
    setSelectedDate(null);
    setModalOpen(false);
    store.setLoading(false);
    if (!displayTimeOff || currentTimeOff.type !== PROJECT_TYPES.VACATION) {
      setShowVacationDaysLeft(false);
    }
  };

  const handleCancel = () => {
    setDisplayDate(null);
    setSelectedDate(null);
    setModalOpen(false);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    try {
      let project_name = currentProject.label;
      let project_type = currentProject.type;
      if (project_type === PROJECT_TYPES.TIME_OFF) {
        project_name = currentTimeOff.label;
        project_type = currentTimeOff.type;
      }

      const fill = {
        date: selectedDate,
        duration: `${currentTime}h`,
        project_name,
        project_type,
      };

      const email = store.supervisorMode ? customUserEmail : userStore.user?.email;

      await store.postTimeFills(fill, email);
      if (applyToAll) {
        const currentDay = new Date(selectedDate);
        while (currentDay.getDay() < 5) {
          currentDay.setDate(currentDay.getDate() + 1);
          const [day, month, year] = currentDay.toLocaleDateString('en-GB').split('/');
          fill.date = `${year}-${month}-${day}`;

          await store.postTimeFills(fill, email);
        }
      }
    } catch {}
    setModalOpen(false);
    setConfirmLoading(false);
  };

  const handleDelete = async (id, currentDate) => {
    setConfirmLoading(true);
    try {
      await store.deleteTimeFill(id);
      onSelectDate(currentDate);
      if (!store.supervisorMode) {
        handleCancel();
      }
    } catch {}
    setConfirmLoading(false);
  };

  const onSelectDate = (current, info) => {
    const newMonthYear = current.format('YYYY-MM');
    if (selectedMonthYear !== newMonthYear) {
      setSelectedMonthYear(current.format('YYYY-MM'));
    }

    const date = current.format('YYYY-MM-DD');
    const data = store.timeFills[date];

    let content = null;

    if (data) {
      content = store.supervisorMode ? (
        <ul className={cx(s.list, s.modalList)}>
          {data.map((item, i) => (
            <li key={item.projectName + i}>
              <Badge color={COLORS[item.projectType]} size="default" count={item.duration}>
                <div className={s.modalTextItem}>
                  {item.user} - {item.projectName}
                </div>
              </Badge>

              {item.projectType !== PROJECT_TYPES.HOLIDAYs && (
                <DeleteOutlined onClick={() => handleDelete(item.id, current)} className={s.remove} />
              )}
            </li>
          ))}
        </ul>
      ) : (
        <ul className={cx(s.list, s.modalList)}>
          {data.map((item, i) => (
            <li key={item.projectName + i}>
              <Badge color={COLORS[item.projectType]} size="default" count={item.duration}>
                <div className={s.modalTextItem}>{item.projectName}</div>
              </Badge>

              {/*<EditOutlined onClick={() => alert(7)} className={s.edit} />*/}
              {item.projectType !== PROJECT_TYPES.HOLIDAYs && (
                <DeleteOutlined onClick={() => handleDelete(item.id, current)} className={s.remove} />
              )}
            </li>
          ))}
        </ul>
      );
    }

    setModalContent(content);
    setDisplayDate(`${current.format('dddd, MMMM D')}, ${current.format('YYYY')}`);
    setSelectedDate(date);
    if (info?.source === 'date') {
      setModalOpen(true);
    }
  };

  const selectProject = (project) => {
    if (project === PROJECTS[1].value) {
      setDisplayTimeOff(true);
      setApplyToAll(false);
    } else if (displayTimeOff) {
      setDisplayTimeOff(false);
    }
    const selected = find(PROJECTS, ['value', project]);
    setCurrentProject(selected);
  };

  const selectTimeOff = (timeOff) => {
    if (timeOff === 'Vacation') {
      store.vacationDaysLeft && setShowVacationDaysLeft(true);
    } else {
      setShowVacationDaysLeft(false);

      if (timeOff === 'Illness') {
        setApplyToAll(false);
      }
    }
    const selected = find(TIME_OFFS, ['value', timeOff]);
    setCurrentTimeOff(selected);
  };

  const onTimeChange = (time) => {
    setCurrentTime(time);
  };

  const disabledDate = (current) => {
    const day = current.day();
    return day === 0 || day === 6;
  };

  const onApplyToAll = (e) => {
    setApplyToAll(e.target.checked);
  };

  const onPanelChange = (value, mode) => {
    console.log(value.format('DD MMM, YYYY'), mode);
  };

  const swithSupervisorMode = (value) => {
    store.setSupervisorMode(value);
    setShowUserSelect(value);
  };

  const dateCellRender = (current) => {
    const date = current.format('YYYY-MM-DD');
    const data = store.timeFills[date];
    if (!data) return null;

    if (store.supervisorMode) {
      const monthYear = current.format('YYYY-MM');
      if (selectedMonthYear !== monthYear) {
        return null;
      }

      return (
        <ul className={cx(s.list, s.cellList, s.cellListSmall)}>
          {orderBy(data, ['user'], ['asc']).map((item, i) => (
            <li key={item.projectName + i}>
              <Badge color={COLORS[item.projectType]} size="small" count={item.duration}>
                <div className={cx(s.cellTextItem, s.cellTextItemSmall)}>
                  {/*{item.user} - {item.projectName}*/}
                  {item.user}
                </div>
              </Badge>
            </li>
          ))}
        </ul>
      );
    }

    return (
      <ul className={cx(s.list, s.cellList)}>
        {data.map((item, i) => (
          <li key={item.projectName + i}>
            <Badge color={COLORS[item.projectType]} size="default" count={item.duration}>
              <div className={s.cellTextItem}>{item.projectName}</div>
            </Badge>
          </li>
        ))}
      </ul>
    );
  };

  const cellRender = (current, info) => {
    if (info.type === 'date') return dateCellRender(current);
    return info.originNode;
  };

  const modalTitle = store.supervisorMode
    ? `${displayDate} - ${userStore.customUser?.fullname} - ${customUserEmail}`
    : displayDate;

  let workHoursDays, vacationHoursDays, illnessHoursDays, holidayHoursDays;
  if (store.supervisorMode) {
    const monthUserData = store.timeFillsByUser[customUserEmail]
      ? store.timeFillsByUser[customUserEmail][selectedMonthYear]
      : null;
    if (monthUserData) {
      const workHours = monthUserData[PROJECT_TYPES.WORK] || 0;
      workHoursDays = workHours ? ` - ${workHours}h - ${workHours / 8}d` : '';
      const vacationHours = monthUserData[PROJECT_TYPES.VACATION] || 0;
      vacationHoursDays = vacationHours ? ` - ${vacationHours}h - ${vacationHours / 8}d` : '';
      const illnessHours = monthUserData[PROJECT_TYPES.ILLNESS] || 0;
      illnessHoursDays = illnessHours ? ` - ${illnessHours}h - ${illnessHours / 8}d` : '';
      const holidayHours = monthUserData[PROJECT_TYPES.HOLIDAY] || 0;
      holidayHoursDays = holidayHours ? ` - ${holidayHours}h - ${holidayHours / 8}d` : '';
    }
  }

  return (
    <PageLayout>
      <div className={s.root}>
        <div className={cx(s.supervisorSwitch, { [s.active]: userStore.user?.isSupervisor })}>
          <Switch onChange={swithSupervisorMode} size="small" />
          {store.supervisorMode && (
            <Switch onChange={setShowUserSelect} size="small" className={s.userSwitch} defaultChecked />
          )}
          {store.supervisorMode && showUserSelect && (
            <Select
              className={s.selectUser}
              onChange={setCustomUserEmail}
              options={userStore.users}
              value={customUserEmail}
              fieldNames={{ value: 'email', label: 'fullname' }}
            />
          )}
        </div>
        {userStore.user?.restricted ? (
          <Alert message="Error" description="This user is not registered." type="error" showIcon className={s.alert} />
        ) : (
          userStore.user && (
            <>
              <Calendar
                key={store.loading}
                onPanelChange={onPanelChange}
                cellRender={cellRender}
                onSelect={onSelectDate}
                disabledDate={disabledDate}
              />
              <Modal
                title={modalTitle}
                open={modalOpen}
                onOk={handleOk}
                okText="Add Time"
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                afterClose={handleModalClose}
                width={store.supervisorMode ? 680 : 520}
              >
                <div className={s.modalBody}>
                  {modalContent}
                  <div className={s.inputRowLabel}>Time to add:</div>
                  <div className={s.inputRow}>
                    <Select style={{ width: 200 }} onChange={selectProject} options={PROJECTS} value={currentProject} />
                    {!displayTimeOff && (
                      <>
                        <InputNumber
                          min={1}
                          max={10}
                          value={currentTime}
                          onChange={onTimeChange}
                          className={s.inputTime}
                        />
                        <div>hours</div>
                      </>
                    )}
                  </div>
                  {displayTimeOff && (
                    <div className={s.timeOffRow}>
                      <Select
                        style={{ width: 200 }}
                        onChange={selectTimeOff}
                        options={TIME_OFFS}
                        value={currentTimeOff}
                      />
                      <InputNumber
                        min={1}
                        max={10}
                        value={currentTime}
                        onChange={onTimeChange}
                        className={s.inputTime}
                      />
                      <div>hours</div>
                    </div>
                  )}
                  {showVacationDaysLeft && (
                    <div className={s.timeOffRow}>
                      <Tag color="geekblue">
                        {store.vacationDaysLeft} available vacation days left in the year {store.year}
                      </Tag>
                    </div>
                  )}
                  <div>
                    <Checkbox
                      onChange={onApplyToAll}
                      checked={applyToAll}
                      disabled={displayTimeOff && currentTimeOff.value === 'Illness'}
                    >
                      Apply to the next days of the week
                    </Checkbox>
                  </div>
                  {store.supervisorMode && (
                    <div className={s.legends}>
                      <div className={s.legend}>
                        <Badge color={COLORS[PROJECT_TYPES.WORK]} size="default" count="8h" />- Work{workHoursDays}
                      </div>
                      <div className={s.legend}>
                        <Badge color={COLORS[PROJECT_TYPES.VACATION]} size="default" count="8h" /> - Vacation
                        {vacationHoursDays}
                      </div>
                      <div className={s.legend}>
                        <Badge color={COLORS[PROJECT_TYPES.ILLNESS]} size="default" count="8h" /> - Illness
                        {illnessHoursDays}
                      </div>
                      <div className={s.legend}>
                        <Badge color={COLORS[PROJECT_TYPES.HOLIDAY]} size="default" count="8h" /> - State Holiday
                        {holidayHoursDays}
                      </div>
                    </div>
                  )}
                </div>
              </Modal>
            </>
          )
        )}
      </div>
    </PageLayout>
  );
}

export default observer(Report);
