import { makeAutoObservable, runInAction } from 'mobx';
import { find } from 'lodash';
import api from '../services';
import { SUPER_USER } from 'const';

const hashRegexp = /([^&=]+)=([^&]*)/g;

export class UserStore {
  constructor(service) {
    this.service = service;
    this.loading = false;
    this.user = null;
    this.customUser = null;
    this.users = [];
    makeAutoObservable(this);
  }

  loadUserOauthData = async () => {
    this.loading = true;

    const fragmentString = window.location.hash.substring(1);

    // Parse query string to see if page request is coming from UserService 2.0 server.
    const params = {};
    let matches;
    while ((matches = hashRegexp.exec(fragmentString))) {
      params[decodeURIComponent(matches[1])] = decodeURIComponent(matches[2]);
    }

    if (Object.keys(params).length > 0) {
      localStorage.setItem('oauth2-test-params', JSON.stringify(params));
      window.history.replaceState(null, null, ' ');

      if (params['state'] === 'try_sample_request') {
        try {
          const user = await this.service.requestOauthUserData();
          this.setUser(user);
        } catch (err) {
          console.log(err);
        }
      }
    } else {
      try {
        const user = await this.service.requestOauthUserData();
        this.setUser(user);
      } catch (err) {
        console.log(err);
      }
    }
    if (this.user.email === SUPER_USER) {
      this.user.isSupervisor = true;
    }

    this.loading = false;
  };

  loadUsers = async (isSupervisor = false) => {
    const users = (await this.service.fetchUsers(this.user?.email, isSupervisor)) || [];
    runInAction(() => {
      this.users = users;
    });

    let myUser = null;
    this.users.forEach((user) => {
      user.fullname = `${user.firstname} ${user.lastname}`;
      user.startDate = user.startdate;
      user.transferedVacationHours = Number(user?.transfered_vacation_hours || 0);

      if (user.email === this.user.email) {
        myUser = user;
      }
    });

    if (myUser) {
      this.user.startDate = myUser.startDate;
      this.user.transferedVacationHours = myUser.transferedVacationHours;
    }
  };

  getUserByEmail = (email) => {
    return find(this.users, ['email', email]);
  };

  getUserNameByEmail = (email) => {
    const user = this.getUserByEmail(email);
    return user ? `${user.firstname} ${user.lastname}` : null;
  };

  setCustomUserEmail = (email, supervisorMode) => {
    if (supervisorMode) {
      this.customUser = this.getUserByEmail(email) || null;
    } else {
      this.customUser = null;
    }
  };

  setLoading = (value) => {
    this.loading = value;
  };

  setUser = (user) => {
    runInAction(() => {
      this.user = user;
    });
  };

  logOut = () => {
    localStorage.setItem('oauth2-test-params', null);
    this.user = null;
    window.location.reload();
  };
}

export const userStore = new UserStore(api.userService);
