import { Routes, Route } from 'react-router-dom';
import { Report } from './pages';
import './App.css';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Report />} />
    </Routes>
  );
}

export default App;
