import React from 'react';
import { Header } from 'components';
import s from './PageLayout.module.scss';

function PageLayout(props) {
  return (
    <div className={s.root}>
      <Header />
      <div className={s.space}>{props.children}</div>
    </div>
  );
}

export default PageLayout;
