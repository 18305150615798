import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Dropdown } from 'antd';
import { userStore as store } from 'stores';
import s from './User.module.scss';

const items = [
  {
    label: <div onClick={store.logOut}>LogOut</div>,
    key: '0',
  },
];

function User() {
  useEffect(() => {
    store.loadUserOauthData();
  }, []);

  if (!store.user) {
    return null;
  }

  const { name, email, picture } = store.user;
  return (
    <div className={s.root}>
      <div className={s.name}>
        <div>{name}</div>
        <div className={s.email}>{email}</div>
      </div>

      <Dropdown menu={{ items }} trigger={['click']} theme="dark">
        <a onClick={(e) => e.preventDefault()}>
          <div className={s.avatar}>
            <img src={picture} alt="" />
          </div>
        </a>
      </Dropdown>
    </div>
  );
}

export default observer(User);
